<template>
  <div class="project-folders">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="文件资料管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <div class="tmap-box" :style="{ height: `${tabberHeight(230)}px` }">
        <div class="tmap-box-aside" style="position: relative">
          <!-- 帐号 -->
          <ml-date-picker
            prop="fileNameLike"
            Placeholder="请选择年"
            type="year"
            valueFormat="YYYY"
            style="margin: 5px 10px 0"
            v-model="searchTreeData.serviceYear"
          />
          <!-- <el-tree ref="folderTreeRef" :props="props" :load="loadNode" lazy node-key="id"> -->
          <el-tree
            :style="{ height: `${tabberHeight(260)}px`, 'overflow-x': 'hidden' }"
            ref="folderTreeRef"
            :data="treeData"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            @node-click="nodeCLick"
            highlight-current
          >
            <template #default="{ node }">
              <span class="custom-tree-node" v-if="node.level === 1">
                <span :class="`tree-span-${node.level}`" :title="node.label">
                  {{ node.label }}
                </span>
              </span>
              <span class="custom-tree-node" v-if="node.level === 2">
                <span :class="`tree-span-${node.level}`" :title="node.label">
                  {{ node.label }}
                </span>
              </span>
              <span class="custom-tree-node" v-if="node.level === 3">
                <span :class="`tree-span-${node.level}`" :title="node.label">
                  {{ node.label }}
                </span>
              </span>
            </template>
          </el-tree>
        </div>
        <div class="tmap-box-main">
          <!-- 路径展示 -->
          <ml-tip :showHeader="false">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item style="cursor: pointer" @click="dblclickCompany('allId')">
                全部
              </el-breadcrumb-item>
              <el-breadcrumb-item
                @click="dblclickCompany(addFile.companyId)"
                v-if="addFile.companyNamepath"
                style="cursor: pointer"
              >
                {{ addFile.companyNamepath }}
              </el-breadcrumb-item>
              <el-breadcrumb-item
                v-if="addFile.projectNamepath"
                @click="dblclickCompany(addFile.projectId)"
                style="cursor: pointer"
              >
                {{ addFile.projectNamepath }}
              </el-breadcrumb-item>
              <!-- 文件夹 -->
              <!-- <el-breadcrumb-item v-if="addFile.folderNamepath">
								{{ addFile.folderNamepath }}
							</el-breadcrumb-item> -->

              <!-- 共存数据 -->
              <div class="coexistence-nav" v-if="addFile.coexistence.length">
                <el-breadcrumb-item
                  v-for="(item, index) in addFile.coexistence"
                  :key="'nav' + item.folderId"
                  @click="coexistenceNavTo(item, index)"
                >
                  {{ item.folderName }}
                </el-breadcrumb-item>
              </div>
            </el-breadcrumb>
          </ml-tip>
          <ml-tip :showHeader="false" style="border: 0">
            <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
              <!-- 帐号 -->
              <ml-input
                prop="fileNameLike"
                placeholder="请输入文件名"
                label="文件名"
                :style="{ marginBottom: 0, marginRight: '40px' }"
                v-model="searchData.fileNameLike"
              />
              <!-- 搜索按钮 -->
              <ml-button
                :style="{ 'margin-bottom': 0 }"
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchData"
                @click-submit="searchFn"
              />

              <!-- 文件夹按钮 -->
              <!-- v-if="addFile.projectNamepath && !addFile.folderNamepath && !addFile.fileNamePath" -->
              <ml-button
                v-if="
                  (addFile.projectNamepath && !addFile.folderNamepath && !addFile.fileNamePath) ||
                  addFile.folderId
                "
                :style="{ 'margin-bottom': 0 }"
                submitText="添加文件夹"
                submitIcon="el-icon-plus"
                cancelText="重命名"
                cancelIcon="el-icon-edit"
                cancelType="primary"
                @click-cancel="updateFolder"
                @click-submit="addFolder"
                :disabledCancel="!isActiveDir"
              />
              <!-- 文件夹按钮 -->
              <ml-button
                v-if="
                  (!!addFile.projectNamepath && !addFile.folderNamepath && !addFile.fileNamePath) ||
                  addFile.folderId
                "
                :style="{ 'margin-bottom': 0 }"
                submitText="删除文件夹"
                submitIcon="el-icon-delete"
                submitType="danger"
                @click-submit="removeFolder"
                :showCancel="false"
                :disabledSubmit="!isActiveDir"
              />
              <!-- 文件夹按钮 -->
              <ml-button
                v-if="!!addFile.projectNamepath && !addFile.folderNamepath && !addFile.fileNamePath"
                :style="{ 'margin-bottom': 0 }"
                submitText="初始化"
                submitIcon="el-icon-refresh"
                submitType="primary"
                @click-submit="refreshFolder"
                :showCancel="false"
              />
              <!-- 文件按钮 -->
              <ml-button
                v-if="!!addFile.folderNamepath || !!addFile.fileNamePath"
                :style="{ 'margin-bottom': 0 }"
                submitText="上传文件"
                submitIcon="el-icon-circle-plus-outline"
                :showSubmit="
                  !!addFile.companyNamepath && !!addFile.projectNamepath && !!addFile.folderNamepath
                "
                cancelText="批量下载"
                cancelIcon="el-icon-download"
                cancelType="primary"
                @click-cancel="clickDownAll"
                @click-submit="clickAdd"
              />
            </ml-form>
          </ml-tip>
          <!-- 阿米吧文件夹 -->
          <ml-tip
            v-if="
              !addFile.companyNamepath &&
              !addFile.projectNamepath &&
              !addFile.folderNamepath &&
              !addFile.fileNamePath
            "
            :showHeader="false"
            class="default-tip"
            :style="{ marginBottom: 0, border: 0 }"
          >
            <el-scrollbar :height="`${tabberHeight(380)}px`">
              <div
                :style="{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px' }"
                v-if="treeData[0].children.length > 0"
              >
                <folder-item
                  v-for="item in treeData[0].children"
                  :key="item"
                  :name="item.label"
                  @click="dblclickCompany(item)"
                />
              </div>
              <el-empty v-else description="暂无阿米巴数据"></el-empty>
            </el-scrollbar>
          </ml-tip>
          <!-- 项目文件夹 -->
          <ml-tip
            v-if="
              addFile.companyNamepath &&
              !addFile.projectNamepath &&
              !addFile.folderNamepath &&
              !addFile.fileNamePath
            "
            :showHeader="false"
            class="default-tip"
            :style="{ marginBottom: 0, border: 0 }"
          >
            <el-scrollbar :height="`${tabberHeight(380)}px`">
              <div
                :style="{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px' }"
                v-if="projectData.length > 0"
              >
                <folder-item
                  v-for="item in projectData"
                  :key="item"
                  :name="item.label"
                  @click="dblclickCompany(item)"
                />
              </div>
              <el-empty v-else description="暂无项目数据"></el-empty>
            </el-scrollbar>
          </ml-tip>

          <!-- 文件夹 -->
          <ml-tip
            v-if="
              addFile.companyNamepath &&
              addFile.projectNamepath &&
              !addFile.folderNamepath &&
              !addFile.fileNamePath
            "
            :showHeader="false"
            class="default-tip"
            :style="{ marginBottom: 0, border: 0 }"
          >
            <el-scrollbar :height="`${tabberHeight(380)}px`">
              <div
                :style="{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px' }"
                v-if="folderData.length > 0"
              >
                <folder-item
                  v-for="item in folderData"
                  :key="item"
                  :name="item.folderName"
                  @dblclick="dblclickDir(item)"
                  @click="clickDir(item)"
                  :isTrue="isActiveDir === item.folderId"
                />
              </div>
              <el-empty v-else description="暂无文件夹"></el-empty>
            </el-scrollbar>
          </ml-tip>

          <!-- 第四级 文件文件夹共存 -->
          <!-- 选项卡 -->
          <div class="coexistence-box" v-if="addFile.folderId">
            <el-tabs
              tab-position="left"
              v-model="coexistenceVal"
              style="height: 100%"
              class="folder-tabs"
            >
              <el-tab-pane label="文件" name="file">
                <!-- 数据表格 -->
                <ml-tip
                  v-if="!!addFile.folderNamepath || !!addFile.fileNamePath"
                  :showHeader="false"
                  class="default-tip"
                  style="margin-bottom: 0"
                >
                  <!-- 表格 -->
                  <el-table
                    :data="tabberData.data"
                    :row-key="row => row.fileId"
                    style="width: 100%"
                    @select-all="selectAll"
                    @select="select"
                    :height="tabberHeight(440)"
                  >
                    <el-table-column type="selection" :reserve-selection="true" width="55" />
                    <!-- <el-table-column
                show-overflow-tooltip
                align="center"
                label="项目名称"
                prop="projectName"
                min-width="15%"
              />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="文件夹名称"
                prop="folderName"
                min-width="15%"
              /> -->
                    <el-table-column
                      show-overflow-tooltip
                      align="center"
                      label="文件名称"
                      prop="fileName"
                      min-width="25%"
                    />
                    <el-table-column
                      show-overflow-tooltip
                      align="center"
                      label="添加时间"
                      prop="createTime"
                      min-width="25%"
                    />
                    <el-table-column align="center" label="操作" priop="fileId" min-width="30%">
                      <template #default="scope">
                        <el-button
                          size="mini"
                          icon="el-icon-View"
                          type="primary"
                          @click="clickPreview(scope.$index, scope.row)"
                        >
                          预览
                        </el-button>
                        <el-button
                          size="mini"
                          icon="el-icon-edit"
                          type="primary"
                          @click="clickView(scope.$index, scope.row)"
                        >
                          查看
                        </el-button>
                        <el-button
                          size="mini"
                          icon="el-icon-delete"
                          type="danger"
                          @click="clickDelete(scope.$index, scope.row)"
                        >
                          删除
                        </el-button>
                        <el-button
                          size="mini"
                          icon="el-icon-unlock"
                          type="primary"
                          @click="clickDown(scope.$index, scope.row)"
                        >
                          下载
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- 分页 -->
                  <ml-pagination
                    :total="tabberData.total"
                    :page="tabberData.page"
                    v-model="tabberData.page"
                    :size="tabberData.size"
                    @current-change="currentChange"
                    @size-change="sizeChange"
                  />
                </ml-tip>
              </el-tab-pane>
              <el-tab-pane label="文件夹" name="folder">
                <!-- 文件夹 -->
                <ml-tip
                  :showHeader="false"
                  class="default-tip"
                  :style="{ marginBottom: 0, border: 0 }"
                >
                  <el-scrollbar :height="`${tabberHeight(380)}px`">
                    <div
                      :style="{ display: 'flex', flexWrap: 'wrap', paddingLeft: '10px' }"
                      v-if="folderData.length > 0"
                    >
                      <folder-item
                        v-for="item in folderData"
                        :key="item"
                        :name="item.folderName"
                        @dblclick="dblclickDir(item)"
                        @click="clickDir(item)"
                        :isTrue="isActiveDir === item.folderId"
                      />
                    </div>
                    <el-empty v-else description="暂无文件夹"></el-empty>
                  </el-scrollbar>
                </ml-tip>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </ml-tip>

    <!-- 添加修改 -->
    <ml-dialog
      width="600px"
      ref="addFolderRef"
      :title="addFolderData.title"
      @click-submit="submitDeleteAddFolder"
    >
      <template #body>
        <ml-form
          :style="{ width: '560px' }"
          ref="addFoldeFormrRef"
          labelWidth="100px"
          :model="addFolderData"
          :rules="addFolderDataRole"
        >
          <ml-input
            prop="folderName"
            placeholder="请输入文件夹名"
            style="margin-bottom: 0"
            label="文件夹名:"
            v-model="addFolderData.folderName"
          />
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 删除账户提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
    <!-- 多选下载提示框 -->
    <ml-dialog
      ref="downAllDialogRef"
      :content="downAllDialogData.content"
      @click-submit="submitDeleteDownAll"
      :showSubmitBtn="downAllDialogData.show"
    />
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteFolderDialogRef"
      content="确定删除该文件夹吗？"
      @click-submit="submitdeleteFolderDialog"
    />
    <!-- 添加修改 -->
    <ml-dialog
      :title="content"
      width="640px"
      ref="addFolderDialogRef"
      @click-submit="submitaddFolderDialog"
      :showSubmitBtn="content === '添加'"
    >
      <template #body>
        <ml-form style="width: 560px" labelWidth="100px" :model="searchData" ref="searchDataRef">
          <el-form-item label="阿米巴">
            <span>{{ addFile.companyName }}</span>
          </el-form-item>
          <el-form-item label="所属项目">
            <span>{{ addFile.projectName }}</span>
          </el-form-item>
          <el-form-item label="文件夹" :style="{ borderBottom: '1px dashed #d8d8d8' }">
            <span>{{ addFile.folderName }}</span>
          </el-form-item>

          <el-form-item label-width="0">
            <ml-list
              fileName="fileName"
              :list="addFile.attachVos"
              :showDelete="content === '添加'"
              :showDown="content === '查看'"
            />
          </el-form-item>

          <ml-button :showSubmit="false" :showCancel="false" v-if="content === '添加'">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </ml-button>
        </ml-form>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { BASEURL, FILEUPLOADURL, FILEDOWNLOAD, prefix } from '@API'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  getTreeString,
  getProjectsString,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import FolderItem from './components/folderItem.vue'
import { Base64 } from 'js-base64'

export default {
  name: 'ProjectFolders',
  components: {
    FolderItem
  },
  setup() {
    const content = ref('')
    const { commit, dispatch, getters } = useStore()
    const toKen = computed(() => getters.getToken)
    /* 开始 树 **********************************/
    const folderTreeRef = ref()
    const dekArray = ref([])
    // 搜索树条件
    const nDate = new Date()
    const year = ref(nDate.getFullYear())
    const searchTreeData = reactive({
      serviceYear: String(year.value)
    })

    // 搜索树
    watch(
      () => searchTreeData.serviceYear,
      () => {
        getTreeDate()
      }
    )

    const treeData = ref([{ children: [], id: 'allId', label: '全部', type: 'all' }])
    const getTreeDate = async () => {
      const { data } = await getTabberData(dispatch, 'fetchGetProjectFolderTreeList', {
        serviceYear: searchTreeData.serviceYear
      })
      if (data && data.length > 0) {
        treeData.value[0].children = data
        nodeCLick(treeData.value[0], { level: 1 })
      }
    }
    // 添加文件夹
    const addFolderRef = ref()
    const addFoldeFormrRef = ref()
    const addFolderData = reactive({
      title: '',
      folderId: '',
      folderName: '',
      companyId: '',
      projectId: ''
    })

    const addFolderDataRole = {
      folderName: [{ required: true, message: '请输入文件夹名 ', trigger: 'blur' }]
    }
    // 添加文件夹
    const addFolder = () => {
      addFolderData.companyId = addFile.companyId
      addFolderData.projectId = addFile.projectId
      addFolderData.folderName = ''
      addFolderData.title = '添加文件夹'
      addFolderRef.value.showDialog = true

      // console.log(addFile)
    }
    // 修改文件夹
    const updateFolder = () => {
      addFolderData.companyId = addFile.companyId
      addFolderData.projectId = addFile.projectId
      addFolderData.folderId = isActiveDir.value
      addFolderData.folderName = isActiveDirName.value
      addFolderData.title = '修改文件夹'
      addFolderRef.value.showDialog = true
    }

    // 删除文件夹
    const deleteFolderDialogRef = ref()
    const removeFolder = () => {
      deleteUserData.folderId = isActiveDir.value
      deleteFolderDialogRef.value.showDialog = true
    }
    const submitdeleteFolderDialog = () => {
      const { folderId } = deleteUserData
      dispatch('fecthDeleteProjectFolder', folderId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          // getTreeDate()

          // 共存中
          if (addFile.folderId) {
            getSuperList()
          } else {
            //项目

            getFolderData(addFile.companyId, addFile.projectId)
          }
          isActiveDir.value = ''
          isActiveDirName.value = ''
          deleteFolderDialogRef.value.showDialog = false
        }
      })
    }

    // 确认添加或修改文件夹
    const submitDeleteAddFolder = () => {
      // 第三级
      const { title, folderName, projectId, folderId, companyId } = addFolderData
      addFoldeFormrRef.value.CustomFormRef.validate().then(() => {
        let nFolderParamsName = 'fetchAddProjectFolder'
        let nFolderParams = {
          companyId,
          folderName,
          projectId
        }
        if (title === '修改文件夹') {
          nFolderParamsName = 'fetchUpdateProjectFolder'
          nFolderParams.folderId = folderId
        } else {
          // 添加共存文件夹
          if (addFile.folderId) {
            // 父级文件夹
            nFolderParams.superId = addFile.folderId
          }
        }
        // console.log(addFolderData)
        // console.log(addFile)

        dispatch(nFolderParamsName, nFolderParams).then(data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })

            // 共享文件
            if (addFile.folderId) {
              getSuperList()
            } else {
              //项目
              getFolderData(companyId, projectId)
            }

            isActiveDir.value = ''
            isActiveDirName.value = ''
            addFolderRef.value.showDialog = false
          }
        })
      })
    }

    // 初始化
    const refreshFolder = () => {
      dispatch('fetchInitProjectFolder', { projectId: addFile.projectId }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '初始化成功',
            type: 'success'
          })
          getFolderData(addFile.companyId, addFile.projectId)
        }
      })
    }

    const nodeCLick = (data, node) => {
      if (node.level === 1) {
        addFile.companyId = ''
        addFile.companyName = ''
        addFile.companyNamepath = ''
        addFile.projectId = ''
        addFile.projectName = ''
        addFile.projectNamepath = ''
        addFile.folderId = ''
        addFile.folderName = ''
        addFile.folderNamepath = ''
        unitOptions.value = data.children || []
        projectData.value = []
        folderData.value = []
      } else if (node.level === 2) {
        addFile.companyId = node.data.id
        addFile.companyName = node.data.label
        addFile.companyNamepath = node.data.label
        addFile.projectId = ''
        addFile.projectName = ''
        addFile.projectNamepath = ''
        addFile.folderId = ''
        addFile.folderName = ''
        addFile.folderNamepath = ''
        projectData.value = data.children || []
        folderData.value = []
      } else if (node.level === 3) {
        addFile.companyId = node.parent.data.id
        addFile.companyName = node.parent.data.label
        addFile.companyNamepath = node.parent.data.label
        addFile.projectId = node.data.id
        addFile.projectName = node.data.label
        addFile.projectNamepath = node.data.label
        addFile.folderId = ''
        addFile.folderName = ''
        addFile.folderNamepath = ''
        getFolderData(addFile.companyId, addFile.projectId)
      }
    }

    /* 结束 树 **********************************/
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      fileNameLike: ''
    })

    const addFile = reactive({
      companyId: '',
      projectId: '',
      folderId: '',
      attachVos: [],

      companyNode: '',
      projectNode: '',
      folderNode: '',
      companyName: '',
      projectName: '',
      folderName: '',
      companyNamepath: '',
      projectNamepath: '',
      folderNamepath: '',
      fileNamePath: '',

      // 共存数据
      coexistence: []
    })

    const linkType = ref('')

    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 文件夹配置
    const folderData = ref([])

    // 处理文件夹列表
    const getFolderData = async (companyId, projectId) => {
      const projectParams = {}
      projectParams.companyId = companyId
      projectParams.projectId = projectId
      const { data } = await getTabberData(dispatch, 'fetchGetProjectFolders', projectParams)
      if (data && data.length > 0) {
        folderData.value = [...data]
      } else {
        folderData.value = []
      }
    }

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      addFile.fileNamePath = ''
      if (addFile.folderName) {
        tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
      } else {
        tabberData.data = []
        tabberData.total = 0
      }
    }
    // 搜索
    const searchFn = () => {
      // console.log(addFile)
      addFile.fileNamePath = searchData.fileNameLike
      tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getUserTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      const { companyId, projectId, folderId } = addFile
      if (companyId) {
        searchDataParams.companyId = companyId
      }
      if (projectId) {
        searchDataParams.projectId = projectId
      }
      if (folderId) {
        searchDataParams.folderId = folderId
      }
      searchDataParams.fileNameLike = replacePerCent(searchData.fileNameLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetProjectFolderFiles',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getUserTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 添加，修改
    const addFolderDialogRef = ref()
    const clickAdd = () => {
      const { companyName, projectName, folderName } = addFile
      content.value = '添加'
      linkType.value = ['project', companyName, projectName, folderName].join('/')
      addFile.attachVos = []
      addFolderDialogRef.value.showDialog = true
    }
    const clickView = async (index, row) => {
      const data = await dispatch('fetchGetProjectFolderFile', row.fileId)
      if (data && data.code === 200 && data.data) {
        const { fileName, filePath } = data.data
        addFile.attachVos = [
          {
            fileName,
            filePath
          }
        ]
      }
      content.value = '查看'
      addFolderDialogRef.value.showDialog = true
    }

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        addFile.attachVos = [...addFile.attachVos, ...files.data]
      }
    }

    const submitaddFolderDialog = () => {
      const { companyId, projectId, folderId, attachVos } = addFile
      const nParams = {
        companyId,
        projectId,
        folderId,
        attachVos
      }
      dispatch('fetchAddProjectFolderFile', nParams).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
          addFolderDialogRef.value.showDialog = false
        }
      })
    }

    // 删除
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该文件吗？'
    })
    const clickDelete = (index, row) => {
      deleteUserData.fileId = row.fileId
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { fileId } = deleteUserData
      dispatch('fetchDeleteProjectFolderFile', fileId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          if (downAllDialogData.selectData.includes(fileId)) {
            downAllDialogData.selectData = downAllDialogData.selectData.filter(
              item => item !== fileId
            )
          }
          tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }
    // 下载fileIds=1&fileIds=2
    const downAllDialogRef = ref()
    const downAllDialogData = reactive({
      show: false,
      content: '',
      selectData: []
    })

    // 选中的添加到列表
    const getSelection = selection => selection.map(item => item.fileId)
    // 选中单个
    const select = selection => {
      downAllDialogData.selectData = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      downAllDialogData.selectData = downAllDialogData.selectData = getSelection(selection)
    }

    const clickDownAll = () => {
      downAllDialogData.content = '请选择要下载的文件'
      downAllDialogData.show = false
      if (downAllDialogData.selectData.length > 0) {
        downAllDialogData.content = '确定下载所选的文件吗？'
        downAllDialogData.show = true
      }
      downAllDialogRef.value.showDialog = true
    }
    const submitDeleteDownAll = () => {
      if (downAllDialogData.selectData.length > 0) {
        const fileIdsStr = downAllDialogData.selectData.join('&fileIds=')
        window.location.href = `${BASEURL}${prefix}/v1/op/projectFileBatchDownload?fileIds=${fileIdsStr}`
      }
      downAllDialogRef.value.showDialog = false
    }
    // 单个下载
    const clickDown = (index, row) => {
      window.location.href = `${FILEDOWNLOAD}/${row.filePath}?downloadFileName=${row.fileName}`
    }

    const clickPreview = (index, row) => {
      let url = `${FILEDOWNLOAD}/${row.filePath}`
      window.open(`${BASEURL}/preview/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`)
    }

    /* new folder 2021-12-2 **********************************************************/
    const isActiveDir = ref('')
    const isActiveDirName = ref('')
    const interval = ref()
    // 双击阿米巴，项目文件夹
    const dblclickCompany = id => {
      isActiveDir.value = ''
      isActiveDirName.value = ''
      const nNode = folderTreeRef.value.getNode(id)
      folderTreeRef.value.setCurrentKey(id)
      nodeCLick(nNode.data, nNode)
    }
    // 双击文件夹
    const dblclickDir = data => {
      clearTimeout(interval.value)
      setFolderInfo(data)

      // 共存中点击普通文件夹
      if (addFile.folderId) {
        // 存放共存 文件夹数据
        addFile.coexistence.push(data)
        getSuperList()
        getUserTabberData()
      } else {
        //第三级
        getUserTabberData()
      }
    }
    // 设置文件夹信息
    const setFolderInfo = data => {
      isActiveDir.value = ''
      isActiveDirName.value = ''
      addFile.folderName = data.folderName
      addFile.folderNamepath = data.folderName
      addFile.folderId = data.folderId
      addFile.folderNode = { folderId: data.folderId, folderName: data.folderName }
    }

    // 单击文件夹
    const clickDir = data => {
      clearTimeout(interval.value)
      interval.value = setTimeout(() => {
        if (!isActiveDir.value || isActiveDir.value !== data.folderId) {
          isActiveDir.value = data.folderId
          isActiveDirName.value = data.folderName
        } else {
          isActiveDir.value = ''
          isActiveDirName.value = ''
        }
      }, 300)
    }

    // 扩展文件 文件夹
    // 共存选项卡
    const coexistenceVal = ref('file')
    // 监听共存数据
    watch(
      () => addFile.folderId,
      nVal => {
        if (!nVal) {
          addFile.coexistence = []
        }
      }
    )

    // 查看共存数据
    const coexistenceNavTo = (item, index) => {
      // 最后一层
      if (index == addFile.coexistence.length - 1) {
        return
      }

      // 清除面包屑 当前往后的
      addFile.coexistence.splice(index + 1)
      setFolderInfo(item)
      getSuperList()
      getUserTabberData()
    }

    // 查询文件夹下共存的文件夹
    const getSuperList = () => {
      const { companyId, projectId, folderId } = addFile
      dispatch('fetchGetProjectFolderSupers', {
        companyId,
        projectId,
        superId: folderId
      }).then(res => {
        const result = res.data || []
        folderData.value = []
        folderData.value = result
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      // const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      // unitOptions.value = data || []
      getTreeDate()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      deleteUserData,
      deleteUserDialogRef,
      submitDeleteUser,
      getTreeString,
      getProjectsString,
      addFolder,
      addFolderRef,
      addFolderData,
      updateFolder,
      submitDeleteAddFolder,
      removeFolder,
      folderTreeRef,
      treeData,
      dekArray,
      deleteFolderDialogRef,
      submitdeleteFolderDialog,
      nodeCLick,
      unitOptions,
      projectData,
      folderData,
      addFile,
      addFolderDialogRef,
      clickAdd,
      action,
      onSuccess,
      toKen,
      linkType,
      submitaddFolderDialog,
      content,
      clickDelete,
      clickView,
      clickDown,
      downAllDialogRef,
      downAllDialogData,
      clickDownAll,
      submitDeleteDownAll,
      selectAll,
      select,
      addFolderDataRole,
      addFoldeFormrRef,
      dblclickCompany,
      isActiveDir,
      dblclickDir,
      clickDir,
      coexistenceVal,
      coexistenceNavTo,
      getSuperList,
      clickPreview,
      refreshFolder,
      searchTreeData
    }
  }
}
</script>

<style lang="scss">
.project-folders {
  @extend %params-global;
  .custom-tree-node {
    // flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    width: 227px;
  }
  .el-range-editor.el-input__inner {
    width: 250px !important;
  }
  .el-tree {
    background-color: transparent;
  }
  // .default-tip-content {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }

  .tmap-box-aside .el-tree-node:focus > .el-tree-node__content,
  .tmap-box-aside .el-tree-node__content:hover,
  .tmap-box-aside .el-upload-list__item:hover {
    .el-button--text {
      color: #001528;
    }
  }

  .tmap-box-main {
    overflow-y: auto;
    .el-card:nth-child(1) {
      .el-card__body {
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .coexistence-nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
